import React, { useState, useEffect, createContext, useContext } from 'react';

const viewportContext = createContext({});

const isBrowser = typeof window !== 'undefined';

var getWidth = () => null;

if (isBrowser) {
  getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
}
let timerId;

const throttleFunction = (func, delay) => {
  if (timerId) {
    return;
  }
  timerId = setTimeout(() => {
    func();
    timerId = undefined;
  }, delay);
};

export const ViewPortProvider = ({ children }) => {
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : null);

  useEffect(() => {
    const handleWindowResize = () =>
      throttleFunction(() => {
        setWidth(getWidth());
      }, 75);

    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width }}>
      {children}
    </viewportContext.Provider>
  );
};

export const useViewport = () => {
  const { width } = useContext(viewportContext);
  return { width };
};
