import React from 'react';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import { Text } from '../text';
import { globalHistory } from '@reach/router';
import navLinks from './navlinks';
import * as headerStyles from '../css/header.module.css';

const NavList = (props) => {
  const pathname = globalHistory.location.pathname;
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.25,
      },
    },
  };

  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  return props.width > props.breakpoint ? (
    <ul className={headerStyles.navList}>
      {navLinks.map((link, i) => (
        <li
          key={i}
          className={`${headerStyles.menuItem} ${
            pathname === link.to || '/' + pathname.split('/')[1] === link.to
              ? headerStyles.activeMenuItem
              : ''
          } `}
        >
          <Link to={link.to}>
            <Text tid={link.label} />
          </Link>
        </li>
      ))}
    </ul>
  ) : (
    props.menuOpen && (
      <motion.div
        className={headerStyles.hamMenu}
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.9 }}
        exit={{ opacity: 0, transition: { duration: 1 } }}
      >
        <motion.ul initial='hidden' animate='show' variants={container}>
          {navLinks.map((link, i) => (
            <motion.li
              key={i}
              className={`${headerStyles.menuItem} ${headerStyles.hamItem} ${
                pathname === link.to || '/' + pathname.split('/')[1] === link.to
                  ? headerStyles.activeMenuItem
                  : ''
              } `}
              onClick={props.handleHamClick}
              variants={item}
            >
              <Link to={link.to}>
                <Text tid={link.label} />
              </Link>
            </motion.li>
          ))}
        </motion.ul>
      </motion.div>
    )
  );
};

export default NavList;
