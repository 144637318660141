import React from 'react';
import github from '../images/icons/github.png';

const en = {
  _web_metadata: '// METADATA //',
  web_title: 'DavidF Web Developer',
  _navlinks: '// NAVLINKS //',
  SOBREMI: 'ABOUT ME',
  TRABAJOS: 'WORKS',
  FORMACION: 'TRAINING',
  CONTACTO: 'CONTACT',

  _aboutme: '// ABOUT ME //',
  aboutme_title: <>About me</>,
  aboutme_description: (
    <div>
      <p>
        Hello! My name is David Fernández, I started in web development in 2020.
      </p>
      <p>
        I first started working in a digital marketing agency mainly with
        wordpress sites, and sometimes with custom websites where I could
        brought React.
      </p>
      <p>
        Now I'm focused mainly in React development and having fun with
        fullstack frameworks.
      </p>
    </div>
  ),

  _works: '// WORKS //',
  confusion_title: <>FICTIONAL RESTAURANT WEBSITE</>,
  confusion_description: (
    <>
      <h6>FEATURES:</h6>
      <ul className='small-list'>
        <li>Dynamic website</li>
        <li>Responsive design</li>
        <li>Login system</li>
        <li>Dishes rating and comment section</li>
      </ul>

      <h6>FRONTEND:</h6>
      <p>
        Built with <b>React</b>, using <b>Redux</b> for state managing. UI with{' '}
        <b>Bootstrap</b>.
      </p>

      <h6>BACKEND:</h6>
      <p>
        Built with <b>nodejs</b> and <b>express</b>. Frontend serving using a{' '}
        <b>REST API</b> with multiple methods to obtain, edit, create and delete
        workers, dishes, comments, promotions and web users. Manage the
        authentication system with <b>JWT</b>.
      </p>
    </>
  ),

  conservas_title: <>FICTIONAL CANNED FOOD WEBSITE</>,
  conservas_description: (
    <>
      <h6>FEATURES:</h6>
      <ul className='small-list'>
        <li>Dynamic website</li>
        <li>Responsive design</li>
        <li>Login system</li>
        <li>Products and shops listing</li>
        <li>Invoice generation</li>
      </ul>

      <h6>FRONTEND:</h6>
      <p>
        Build with <b>Bootstrap</b> and <b>jQuery</b>.
      </p>

      <h6>BACKEND:</h6>
      <p>
        Built with <b>PHP</b> and <b>MYSQL</b> with products, shops and user
        listing. Registration system with data validation. Registering
        confirmation via email. Registered users can pick produts and generate
        an automatic invoice.
      </p>
    </>
  ),
  fragment_title: <>SEARCH YOUR BOOK AND TAKE NOTES</>,
  fragment_description: (
    <>
      <h6>FEATURES:</h6>
      <ul className='small-list'>
        <li>Typesafe web app</li>
        <li>Responsive design</li>
        <li>Auth system</li>
      </ul>

      <h6>FULLSTACK WEB:</h6>
      <div>
        <p>
          A fullstack web app with a typesafe approach built with <b>NextJS</b>,
          and styled with <b>Tailwind</b>.
        </p>
        <p>
          The app queries the Google Books API, through <b>React Query</b>, and
          presents the response as an infinite scroll. The books can be saved in
          a a shelf. You can sign in, with an email magic link procedure.
        </p>
        <p>
          The server API is built with <b>tRPC</b> so the api schema is inferred
          to the client, as simple as that. <b>Prisma</b> ORM is used to query
          the databse.
        </p>

        <a
          href='https://github.com/davidfers/fragment'
          target='_blank'
          rel='noreferrer'
        >
          <img
            src={github}
            width={32}
            style={{ filter: 'var(--grayscale)' }}
            alt='github logo'
          />
        </a>
      </div>
    </>
  ),

  training: '// TRAINING //',
  syllabus: <>Syllabus</>,
  react_title: <h3>Front-End Web Development with React</h3>,
  react_syllabus: (
    <ol>
      <li>
        Introduction to React
        <ul>
          <li>JSX</li>
          <li>React components</li>
        </ul>
      </li>
      <li>
        React Router and Single Page Applications
        <ul>
          <li>Component types</li>
          <li>React router</li>
          <li>Single page applications</li>
        </ul>
      </li>
      <li>
        React Forms, Flow Architecture and Introduction to Redux
        <ul>
          <li>Uncontrolled and controlled forms</li>
          <li>Flux architecture</li>
          <li>Redux</li>
        </ul>
      </li>
      <li>
        More Redux and Client-Server Communication
        <ul>
          <li>Redux action, combining reducers, and Redux thunk</li>
          <li>Client-server communication using Fetch and the REST API</li>
          <li>Animation in React</li>
          <li>Testing, building and deploying React applications</li>
        </ul>
      </li>
    </ol>
  ),
  node_title: <h3>Server-side Development with NodeJS, Express and MongoDB</h3>,
  node_syllabus: (
    <ol>
      <li>
        Introduction to Server-side Development
        <ul>
          <li>Introduction to Node.js and NPM</li>
          <li>Node and HTTP</li>
          <li>Introduction to Express</li>
        </ul>
      </li>
      <li>
        Data
        <ul>
          <li>Express Generator</li>
          <li>Introduction to MongoDB</li>
          <li>Node and MongoDB</li>
          <li>REST API with Express, MongoDB and Mongoose</li>
        </ul>
      </li>
      <li>
        Authentication
        <ul>
          <li>Basic authentication</li>
          <li>Express Sessions</li>
          <li>JWT Authentication with Passport</li>
          <li>Mongoose Population</li>
        </ul>
      </li>
      <li>
        Backend as a Service
        <ul>
          <li>HTTPS and Secure Communication</li>
          <li>Uploading Files</li>
          <li>Cross-Origin Resource Sharing</li>
          <li>OAuth and User Authentication</li>
        </ul>
      </li>
    </ol>
  ),
  upc_title: <h3>Web application development</h3>,
  upc_syllabus: (
    <ol>
      <li>
        Frontend web development
        <ul>
          <li>Web document development with markup language </li>
          <li>
            Software component development and reutilisation with script
            languages
          </li>
          <li>
            Usability and accessibility techniques application in frontend
          </li>
        </ul>
      </li>
      <li>
        Backend web development
        <ul>
          <li>Web app development in backend</li>
          <li>Web app data access in backend</li>
          <li>Distributed web app development</li>
        </ul>
      </li>
      <li>
        Internet, intranet and extranet web app implementation
        <ul>
          <li>Web app deployment</li>
          <li>Version Control</li>
          <li>Web app documentation</li>
        </ul>
      </li>
    </ol>
  ),
  contact: '// CONTACT //',
  name: 'name',
  email: 'email',
  message: 'message',
  submit: 'send',
  sending: 'sending...',
  error: 'This field cannot be empty...',
  success: <>The form has been successfully sent!</>,
};

export default en;
