// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-training-js": () => import("./../../../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */),
  "component---src-pages-works-js": () => import("./../../../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */),
  "component---src-pages-works-page-0-js": () => import("./../../../src/pages/works/page-0.js" /* webpackChunkName: "component---src-pages-works-page-0-js" */),
  "component---src-pages-works-page-1-js": () => import("./../../../src/pages/works/page-1.js" /* webpackChunkName: "component---src-pages-works-page-1-js" */),
  "component---src-pages-works-page-2-js": () => import("./../../../src/pages/works/page-2.js" /* webpackChunkName: "component---src-pages-works-page-2-js" */)
}

