import React from 'react';
import { Helmet } from 'react-helmet';
import './css/layout.css';
import * as layoutStyles from './css/new.module.css';
import Header from './header/header';
import Footer from './footer';
import { ViewPortProvider } from '../context/viewportContext';
import { LanguageProvider } from '../context/languageContext';
import Transition from './transition';

const Layout = (props) => {
  return (
    <ViewPortProvider>
      <LanguageProvider>
        <Helmet>
          <meta charSet='utf-8' />
          <meta
            name='description'
            content='David Fernandez Web Developer Portofolio'
          />
          <title>David F · Web Developer</title>
          <link rel='canonical' href='https://davidfernandez.netlify.app' />
          <meta
            name='google-site-verification'
            content='_cXNsfS5sjgHkPASYphja_OVK3o_HKT9Sz5EfN1yg-U'
          />
        </Helmet>
        <div className={`${layoutStyles.rootContainer} rootContainer `}>
          <Header />

          <div className={`${layoutStyles.mainContainer} mainContainer`}>
            <Transition>{props.children}</Transition>
            <Footer />
          </div>
        </div>
      </LanguageProvider>
    </ViewPortProvider>
  );
};

export default Layout;
