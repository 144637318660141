import React from 'react';
import github from '../images/icons/github.png';

const es = {
  _web_metadata: '// METADATA //',
  web_title: 'DavidF Desarrollador Web',
  _comment: '// NAVLINKS //',
  SOBREMI: 'SOBRE MI',
  TRABAJOS: 'TRABAJOS',
  FORMACION: 'FORMACIÓN',
  CONTACTO: 'CONTACTO',

  _aboutme: '// ABOUT ME //',
  aboutme_title: <>Sobre mi</>,
  aboutme_description: (
    <>
      <p>
        Hola! Me llamo David Fernández, comencé en el desarrollo web en 2020.
      </p>
      <p>
        Empecé trabajando en un agencia de marketing digital, principalmente con
        sitios wordpress y con algún proyecto de web a medida, donde podía hacer
        uso de React.
      </p>
      <p>
        Ahora estoy enfocado principalmente en desarrollo con React y
        divirtiendome con frameworks fullstack.
      </p>
    </>
  ),

  _works: '// WORKS //',
  confusion_title: <>WEB DE RESTAURANTE FICTICIO</>,
  confusion_description: (
    <>
      <h6>CARACTERÍSTICAS:</h6>
      <ul className='small-list'>
        <li>Web dinámica</li>
        <li>Diseño responsive</li>
        <li>Sistema de login</li>
        <li>Apartado para comentar y puntuar platos</li>
      </ul>

      <h6>FRONTEND:</h6>
      <p>
        Construido con <b>React</b>, utilizando <b>Redux</b> para la gestión de
        estados. UI con <b>Bootstrap</b>.
      </p>

      <h6>BACKEND:</h6>
      <p>
        Construido con <b>nodejs</b> y <b>express</b>. Para servir al frontend
        utiliza una <b>API REST</b> con diferentes métodos para obtener, editar,
        crear y borrar trabajadores, platos, comentarios, promociones y usuarios
        de la web. Gestiona el sistema de autenticación mediante <b>JWT</b>.
      </p>
    </>
  ),

  conservas_title: <>WEB DE CONSERVAS FICTICIA</>,
  conservas_description: (
    <>
      <h6>CARACTERÍSTICAS:</h6>
      <ul className='small-list'>
        <li>Web dinámica</li>
        <li>Diseño responsive</li>
        <li>Sistema de login</li>
        <li>Listado de productos y tiendas</li>
        <li>Generación de facturas</li>
      </ul>

      <h6>FRONTEND:</h6>
      <p>
        Construido con <b>Bootstrap</b> y <b>jQuery</b>.
      </p>

      <h6>BACKEND:</h6>
      <p>
        Construido en <b>PHP</b>, bbdd <b>MYSQL</b> con listado de productos,
        tiendas y usuarios. Sistema de registro con validación de datos,
        confirmación de registro por email. El usuario registrado puede
        seleccionar productos y generar una factura automática.
      </p>
    </>
  ),
  fragment_title: <>BUSCA TU LIBRO Y TOMA NOTAS</>,
  fragment_description: (
    <>
      <h6>CARACTERÍSTICAS:</h6>
      <ul className='small-list'>
        <li>Seguridad de tipos</li>
        <li>Diseño responsive</li>
        <li>Autenticación con enlaces mágicos</li>
      </ul>

      <h6>FULLSTACK WEB:</h6>
      <div>
        <p>
          Una aplicación web fullstack con un enfoque en la seguridad de tipos,
          construida con <b>NextJS</b> y diseñada con <b>Tailwind</b>.
        </p>
        <p>
          La aplicación consulta la API de Google Books haciendo uso de{' '}
          <b>React Query</b>, y presenta la respuesta como un scroll infinito
          Los libros pueden guardarse en una estantería. El proceso de
          autenticación se hace mediante el uso de enlaces mágicos que se envian
          por email.
        </p>
        <p>
          La API del servidor esta contruida con <b>tRPC</b>, así que el esquema
          de la api es deducido automaticamente por el cliente. Para consultas a
          la base de datos se utiliza el ORM <b>Prisma</b>.
        </p>
        <a
          href='https://github.com/davidfers/fragment'
          target='_blank'
          rel='noreferrer'
        >
          <img
            src={github}
            width={32}
            style={{ filter: 'var(--grayscale)' }}
            alt='github logo'
          />
        </a>
      </div>
    </>
  ),
  training: '// TRAINING //',
  syllabus: <>Temario</>,
  react_title: <h3>Desarrollo web en entorno cliente con React</h3>,
  react_syllabus: (
    <ol>
      <li>
        Introducción a React
        <ul>
          <li>JSX</li>
          <li>Componentes de React</li>
        </ul>
      </li>
      <li>
        React Router y Single Page Applications
        <ul>
          <li>Tipos de componentes</li>
          <li>React router</li>
          <li>Single page applications</li>
        </ul>
      </li>
      <li>
        Formularios en React, arquitectura de flujo de datos, introducción a
        Redux
        <ul>
          <li>Formularios controlados y no controlados</li>
          <li>Arquitectura Flux</li>
          <li>Redux</li>
        </ul>
      </li>
      <li>
        Más Redux y comunicación cliente-servidor
        <ul>
          <li>Acciónes en Redux, combinando reductores, Redux thunk</li>
          <li>Comunicación cliente-servidor usando Fetch y una API REST</li>
          <li>Animación en React</li>
          <li>Testeando, construyendo y desplegando aplicaciones React</li>
        </ul>
      </li>
    </ol>
  ),
  node_title: (
    <h3>Desarrollo en entorno servidor con NodeJS, Express y MongoDB</h3>
  ),
  node_syllabus: (
    <ol>
      <li>
        Introducción al desarrollo en entorno servidor
        <ul>
          <li>Introducción a Node.js y NPM</li>
          <li>Node y HTTP</li>
          <li>Introducción a Express</li>
        </ul>
      </li>
      <li>
        Data
        <ul>
          <li>Express Generator</li>
          <li>Introducción a MongoDB</li>
          <li>Node y MongoDB</li>
          <li>API REST con Express, MongoDB y Mongoose</li>
        </ul>
      </li>
      <li>
        Autenticación
        <ul>
          <li>Autenticación básica</li>
          <li>Sesiones en Express</li>
          <li>Autenticación JWT con Passport</li>
          <li>Mongoose Population</li>
        </ul>
      </li>
      <li>
        Backend como servicio
        <ul>
          <li>HTTPS y comunicación segura</li>
          <li>Subiendo archivos</li>
          <li>Intercambio de recursos de origen cruzado (CORS)</li>
          <li>OAuth y autenticación de usuario</li>
        </ul>
      </li>
    </ol>
  ),
  upc_title: <h3>Desarrollo de aplicaciones con tecnologías web</h3>,
  upc_syllabus: (
    <ol>
      <li>
        Desarrollar elementos software en el entorno cliente.
        <ul>
          <li>Elaboración de documentos web mediante lenguajes de marcas </li>
          <li>
            Desarrollo y reutilización de componentes software y multimedia
            mediante lenguajes de guión
          </li>
          <li>
            Aplicación de técnicas de usabilidad y accesibilidad en el entorno
            cliente
          </li>
        </ul>
      </li>
      <li>
        Programación web en el entorno servidor
        <ul>
          <li>Desarrollo de aplicaciones web en el entorno servidor (PHP)</li>
          <li>Acceso a datos en aplicaciones web del entorno servidor (SQL)</li>
          <li>Desarrollo de aplicaciones web distribuidas</li>
        </ul>
      </li>
      <li>
        Implantación de aplicaciones Web en entornos internet, intranet y
        extranet
        <ul>
          <li>Despliegue de aplicaciones web</li>
          <li>Control de versiones</li>
          <li>Documentar aplicaciones web</li>
        </ul>
      </li>
    </ol>
  ),

  contact: '// CONTACT //',
  name: 'nombre',
  email: 'email',
  message: 'mensaje',
  submit: 'enviar',
  sending: 'enviando...',
  error: 'Este campo no puede estar vacio...',
  success: <>El formulario se ha enviado correctamente!</>,
};
export default es;
