import React, { useContext } from 'react';
import * as headerStyles from '../css/header.module.css';
import { LanguageContext } from '../../context/languageContext';

export default function LanguageSelector() {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);

  const toggleLanguage = (e) => {
    if (e.target.innerHTML === 'EN') {
      userLanguageChange('en');
    } else {
      userLanguageChange('es');
    }
  };
  // set selected language by calling context method
  return (
    <div
      onClick={toggleLanguage}
      onKeyDown={(e) => (e.code === 'Enter' ? toggleLanguage(e) : null)}
      style={{ cursor: 'pointer' }}
      className={headerStyles.languageSelector}
      role='button'
      tabIndex='0'
    >
      {userLanguage === 'es' ? 'EN' : 'ES'}
    </div>
  );
}
