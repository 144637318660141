import React, { useState, createContext } from 'react';
import { languageOptions, dictionaryList } from '../languages';

// create the language context with default selected language
export const LanguageContext = createContext({
  userLanguage: 'es',
  dictionary: dictionaryList.es,
});

// it provides the language context to app
export function LanguageProvider({ children }) {
  const isBrowser = typeof window !== 'undefined';

  const defaultLanguage = isBrowser
    ? window.localStorage.getItem('lang')
    : 'en';
  const systemLanguage = isBrowser
    ? navigator.language.split('-')[0] === 'es' ||
      navigator.language.split('-')[0] === 'ca'
      ? 'es'
      : 'en'
    : 'en';

  const [userLanguage, setUserLanguage] = useState(
    defaultLanguage || systemLanguage
  );

  if (isBrowser) {
    document.documentElement.setAttribute(
      'lang',
      defaultLanguage || systemLanguage
    );
  }

  const provider = {
    userLanguage,
    dictionary: dictionaryList[userLanguage],
    userLanguageChange: (selected) => {
      const newLanguage = languageOptions[selected] ? selected : 'es';
      setUserLanguage(newLanguage);
      if (isBrowser) {
        window.localStorage.setItem('lang', newLanguage);
      }
    },
  };

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
}
