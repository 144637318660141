import React, { useEffect, useState } from 'react';
import { useViewport } from '../../context/viewportContext';
import * as headerStyles from '../css/header.module.css';
import SunImg from './sunImg';
import MoonImg from './moonImg';

const DarkToggler = () => {
  const [isDark, setIsDark] = useState(false);
  const [touched, setTouched] = useState(false);

  const { width } = useViewport();
  const smallest = 480;

  const handleToggler = () => {
    setIsDark(!isDark);
    setTouched(true);
  };

  //Access one time after render, set dark state taking into account localstorage or system default
  useEffect(() => {
    if (
      window.localStorage.getItem('color-mode') === 'dark' ||
      (typeof localStorage.getItem('color-mode') !== 'string' &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      setIsDark(true);
    }

    if (
      window.localStorage.getItem('color-mode') === 'light' ||
      (typeof localStorage.getItem('color-mode') !== 'string' &&
        window.matchMedia('(prefers-color-scheme: light)').matches)
    ) {
      setIsDark(false);
    }
    // onchange check changes in system default theme and set dark mode state
    const checkDark = (e) => {
      const darkModeOn = e.matches;
      if (!window.localStorage.getItem('color-mode')) {
        if (darkModeOn) {
          setIsDark(true);
        } else {
          setIsDark(false);
        }
      }
    };
    const darkModeMediaQuery = window.matchMedia(
      '(prefers-color-scheme: dark)'
    );

    darkModeMediaQuery.addEventListener('change', checkDark);
  }, []);

  //when change in isDark state , set/change html attribute and localstorage color-mode item
  useEffect(() => {
    if (touched) {
      localStorage.setItem('color-mode', isDark ? 'dark' : 'light');
      document.documentElement.setAttribute(
        'data-force-color-mode',
        isDark ? 'dark' : 'light'
      );
    }
  }, [isDark, touched]);
  return (
    <div
      onClick={handleToggler}
      onKeyDown={(e) => (e.code === 'Enter' ? handleToggler(e) : null)}
      style={{ cursor: 'pointer' }}
      className={
        width > smallest ? headerStyles.headerImg : headerStyles.headerImgSmall
      }
      role='button'
      tabIndex='0'
    >
      {isDark ? <MoonImg /> : <SunImg />}
    </div>
  );
};

export default DarkToggler;
