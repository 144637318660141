import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import sun from '../../images/sun-svgrepo-com.svg';

const SunImg = () => (
  <AnimatePresence exitBeforeEnter>
    <motion.img
      animate={{ rotate: 360 }}
      transition={{ duration: 2 }}
      id='labelImg'
      src={sun}
      alt='light bulb'
    />
  </AnimatePresence>
);

export default SunImg;
