import React, { useState, useEffect, useRef } from 'react';
/* import useScrollDirection from '../../hooks/useScrollDirection' */
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { AnimatePresence } from 'framer-motion';
import * as headerStyles from '../css/header.module.css';
import { useViewport } from '../../context/viewportContext';
import DarkToggler from './darkmode';
import Hamburguer from './hamburguer';
import LanguageSelector from './languageSelector';
import NavList from './navlist';

const Header = () => {
  const { width } = useViewport();
  const breakpoint = 950;
  const smallRef = useRef(width < breakpoint);

  const [menuOpen, setMenuOpen] = useState(false);
  const [hideOnScroll, setHideOnScroll] = useState(true);

  const scrollRef = useRef(null);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
      if (!menuOpen) scrollRef.current = window.scrollY;
    },
    [hideOnScroll],
    false,
    false,
    300
  );

  useEffect(() => {
    smallRef.current = width < breakpoint ? true : false;
  }, [width]);

  useEffect(() => {
    if (
      menuOpen &&
      smallRef.current &&
      document.querySelector('.mainContainer')
    ) {
      const main = document.querySelector('.mainContainer');
      main.style.filter = 'blur(5px)';
      main.style.top = `-${
        scrollRef.current -
        document.querySelector('.headerContainer').clientHeight
      }px`;
      main.style.position = 'fixed';
    } else if (document.querySelector('.mainContainer')) {
      const main = document.querySelector('.mainContainer');
      main.style.filter = null;
      main.style.position = null;
      main.style.top = null;
      window.scrollTo(0, scrollRef.current);
    }
  }, [menuOpen, width]);

  const handleHamClick = (e) => {
    if (!menuOpen) scrollRef.current = window.scrollY;
    setMenuOpen(!menuOpen);
    e.currentTarget.classList.toggle('isActive');
  };

  return (
    <div
      className={`${headerStyles.headerContainer} headerContainer`}
      style={{
        top: !hideOnScroll && smallRef.current ? -80 : 0,
        position: smallRef.current ? 'sticky' : 'relative',
      }}
    >
      <DarkToggler />
      <LanguageSelector />
      <AnimatePresence>
        <NavList
          width={width}
          breakpoint={breakpoint}
          menuOpen={menuOpen}
          handleHamClick={handleHamClick}
        />
      </AnimatePresence>
      {width < breakpoint && (
        <Hamburguer handleHamClick={handleHamClick} isOpen={menuOpen} />
      )}
    </div>
  );
};

export default Header;
