import React from 'react';
import { AnimatePresence, AnimateSharedLayout } from 'framer-motion';

const Transition = (props) => {
  return (
    <AnimateSharedLayout>
      <AnimatePresence exitBeforeEnter>{props.children}</AnimatePresence>
    </AnimateSharedLayout>
  );
};

export default Transition;
