import React from 'react';
import * as hamStyles from '../css/hamburguer.module.css';

const Hamburguer = (props) => {
  return (
    <button
      onClick={props.handleHamClick}
      className={`${hamStyles.hamburger} ${hamStyles.hamburgerSpin} ${
        props.isOpen ? hamStyles.isActive : null
      }`}
      type='button'
      aria-label='menu button'
    >
      <span className={hamStyles.hamburgerBox}>
        <span className={hamStyles.hamburgerInner}></span>
      </span>
    </button>
  );
};

export default Hamburguer;
