import './src/styles/styles.css';
import React from 'react';
import Layout from './src/components/layout';

export const onInitialClientRender = () => {
  require('typeface-crimson-text');
  require('typeface-rosario');
  require('typeface-montserrat');
};

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);
