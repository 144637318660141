import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import moon from '../../images/moon-svgrepo-com.svg';

const MoonImg = () => (
  <AnimatePresence exitBeforeEnter>
    <motion.img
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.5 }}
      id='labelImg'
      src={moon}
      alt='light bulb'
    />
  </AnimatePresence>
);

export default MoonImg;
