import React from 'react';
import * as footerStyles from './css/footer.module.css';
import github from '../images/icons/github.png';

const Footer = () => (
  <div className={footerStyles.footerContainer}>
    <p>David Fernández - 2022 </p>
    <a href='https://github.com/davidfers' target='_blank' rel='noreferrer'>
      <img src={github} alt='github logo' width={24} />
    </a>
  </div>
);

export default Footer;
